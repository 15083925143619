<template>
  <Toast />
  <ConfirmPopup />
  <div class="grid p-fluid">
    <div class="col-12 text-center" v-if="stripeAccount === undefined">
      <ProgressSpinner />
    </div>
    <div class="col-12" v-else>
      <Message
        v-if="stripeAccount.data?.detailsSubmitted === false"
        severity="warn"
      >
        El registro de tu cuenta de Stripe se encuentra incompleto. Para poder
        utilizar la política de cancelación de forma efectiva debes finalizar el
        registro de tu cuenta de Stripe.
      </Message>
      <div class="field-checkbox mt-3">
        <Checkbox
          id="enableCancellationPolicy"
          :binary="true"
          v-model="enableCancellationPolicy"
          @change="updateEnableCancellationPolicy"
        />
        <label for="enableCancellationPolicy"
          >Habilitar política de cancelación</label
        >
      </div>

      <div class="field mt-6 lg:col-4">
        <label for="amountPerDinero" style="font-size: 1em"
          >Cantidad a cobrar por comensal</label
        >
        <InputNumber
          v-model="amountPerDiner"
          id="amountPerDiner"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          :min="0.01"
          mode="currency"
          currency="EUR"
          currencyDisplay="symbol"
          :disabled="!enableCancellationPolicy"
          @blur="updateAmountPerDiner"
        />
      </div>

      <div class="field col-4">
        <label for="cancellationPolicyWarningText"
          >Texto de advertencia de política de cancelación que se mostrará al
          hacer la reserva</label
        >
        <InputText
          id="cancellationPolicyWarningText"
          type="text"
          v-model="warningText"
          :disabled="!enableCancellationPolicy"
          @blur="updateWarningText"
        />
      </div>

      <div class="field col-4" v-if="stripeAccount.data === null || stripeAccount.data.detailsSubmitted === false">
        <Button
          class="w-auto"
          :disabled="!enableCancellationPolicy"
          :loading="loadingAccountLink"
          @click="connectAccount"
          >Conectar con Stripe</Button
        >
      </div>
      <div class="field col-4" v-else>
        <Button
          class="w-auto p-button-danger"
          :disabled="!enableCancellationPolicy"
          :loading="loadingStripeDisconnect"
          @click="disconnectAccount"
          >Desconectar Stripe</Button
        >
        <p class="mt-2">
          Estás conectado con la cuenta de Stripe
          <strong>{{ stripeAccount.data.email }}</strong
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationService from '../../service/OrganizationService';
import api from '../../api/Acutame';

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService
      .getConfig(this.$route.params.id)
      .then((config) => this.parseConfig(config));
    api
      .getStripeAccount(this.$route.params.id)
      .then((account) => this.parseStripeAccount(account));
  },
  data() {
    return {
      organizationService: null,
      idEdit: [],
      enableCancellationPolicy: undefined,
      amountPerDiner: undefined,
      warningText: undefined,
      stripeConnectAccountId: undefined,
      loadingAccountLink: false,
      loadingStripeDisconnect: false,
      stripeAccount: undefined,
      loading: false,
    };
  },
  methods: {
    updateAmountPerDiner() {
      setTimeout(async () => {
        if (this.amountPerDiner === null) return;
        await this.organizationService.putAmountPerDiner(
          this.$route.params.id,
          this.amountPerDiner
        );
        this.$toast.add({
          severity: 'success',
          summary: 'Cantidad por comensal actualizada.',
          life: 3000,
        });
      }, 5);
    },
    updateWarningText() {
      setTimeout(async () => {
        await this.organizationService.putWarningText(
          this.$route.params.id,
          this.warningText
        );
        this.$toast.add({
          severity: 'success',
          summary: 'Texto de advertencia actualizado.',
          life: 3000,
        });
      }, 5);
    },
    updateEnableCancellationPolicy() {
      setTimeout(async () => {
        await this.organizationService.putEnableCancellationPolicy(
          this.$route.params.id,
          this.enableCancellationPolicy
        );
        if (this.enableCancellationPolicy === true) {
          this.$toast.add({
            severity: 'success',
            summary: 'Política de cancelación habilitada.',
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: 'success',
            summary: 'Política de cancelación deshabilitada.',
            life: 3000,
          });
        }
      }, 5);
    },
    parseConfig(config) {
      this.enableCancellationPolicy =
        config.cancellationPolicy.enableCancellationPolicy;
      this.amountPerDiner = config.cancellationPolicy.amountPerDiner;
      this.warningText = config.cancellationPolicy.warningText;
      this.stripeConnectAccountId =
        config.cancellationPolicy.stripeConnectAccountId;
    },
    parseStripeAccount(account) {
      this.stripeAccount = account;
    },
    async connectAccount(callback) {
      this.loadingAccountLink = true;
      let retrievedUrl;
      try {
        const { url } = await api.connectStripeAccount(this.$route.params.id);
        retrievedUrl = url;
      } catch(error) {
        this.$toast.add({
          severity: 'error',
          summary: 'No se pudo conectar con Stripe',
          detail: 'No se ha podido contactar con Stripe para conectar su cuenta. Por favor, inténtelo más tarde.',
          life: 5000,
        });
        this.loadingAccountLink = false;
        return;
      }
      this.loadingAccountLink = false;
      if (retrievedUrl) {
        window.location.assign(retrievedUrl);
      } else {
        callback?.();
      }
    },
    disconnectAccount(e) {
      this.$confirm.require({
        target: e.currentTarget,
        message:
          'Al desconectar tu cuenta de Stripe no se podrá aplicar la política de cancelación a nuevos clientes. ¿Continuar?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
        acceptClass: 'p-button-sm p-button-danger',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Continuar',
        accept: () => {
          this.handleDisconnectAccount();
        },
        reject: () => {},
      });
    },
    async handleDisconnectAccount() {
      this.loadingStripeDisconnect = true;
      const stripeAccount = await api.disconnectStripeAccount(
        this.$route.params.id
      );
      this.loadingStripeDisconnect = false;
      this.stripeAccount = stripeAccount;
      this.$toast.add({
        severity: 'info',
        summary: 'Cuenta de Stripe desconectada con éxito.',
        detail: 'Tu cuenta de Stripe actual ha sido desconectada con éxito.',
        life: 3000,
      });
    },
  },
};
</script>

<style>
.title {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
</style>
